import NoDataImg from 'assets/images/nodata.svg'

const NoResultFound = () => (
  <div className='line-updates-empty'>
    <div className='w-100 d-flex justify-content-center align-items-center flex-column gap-3'>
      <img src={NoDataImg} alt='Line Updates' width='375' height='134' />
      <h6>No Record Available</h6>
    </div>
  </div>
)

export default NoResultFound