import React, { useEffect } from 'react';

const DefaultAltTextSetter = ({data}) => {
	console.log(data)
    useEffect(() => {
        const setDefaultAltText = () => {
            const allImages = document.querySelectorAll('.corporate img');
			allImages.forEach(img => {
				const siblingHeading = img.nextElementSibling ? img.nextElementSibling.querySelector('h5') : null;
				if (siblingHeading) {
					if (!img.alt) {
						img.alt = siblingHeading.textContent.trim();
					}
				} else {
					// Check if data.title is defined or provide a default alt text
					if (!img.alt) {
						img.alt = data.title || 'Default Alt Text';
					}
				}
			});
        };

        // Call the function when the component mounts
        setDefaultAltText();
		
		const setDefaultAltTextLink = () => {
            const allLinks = document.querySelectorAll('.row.g-3 a');
            allLinks.forEach(link => {
                // Set the title of the link to the text content inside the <a> tag
                link.title = link.textContent.trim();
            });
        };

        // Call the function when the component mounts
        setDefaultAltTextLink();

        // Cleanup function (optional)
        return () => {
            // Any cleanup code if needed
        };
    }, [data]);

    return null; // This component does not render anything
};

export default DefaultAltTextSetter;
