import State from 'components/common/State'
import { LocaleContext } from 'App'
import Footer from 'components/corporate/Footer'
import { API_BASE } from 'helpers/pageUtils'
import React, { useContext,Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment'


export default function PressRelease({ data }) {
  const { t } = useTranslation('common')
  const lang = useContext(LocaleContext)
  if (!data) {
    return (
      <>
        <State.Errored message="We didn't find any data" />
        <Footer />
      </>
    )
  }
  return (
    <>
      <div className='pressbanner'>
        <h1>{t('corporate-header.Press-Releases')}</h1>
      </div> 
      {data ? (
        <>
          {Array.isArray(data) &&
          data?.length ? (
		  <div className="container pb-3">
		   <h2>{t('corporate-header.Press-Releases')}</h2>
            <div className='table-responsive press-releasepage'>
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th>Headline</th>
                    <th style={{width:'130px'}}>Date</th>
                    {/* <th>{t('career-section.table-h3')}</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data?.map((d, i) => (
                    <tr key={i}>
                      {/* <td>{d?.advt_no}</td> */}
                      <td>
                      <Fragment key={i}>
                            <span>
                            <Link to={`/pages/${lang}/pressrelease_details/${d.page_slug}`}>
                                {d && d?.english_title ? d?.english_title : d?.hindi_title}
                              </Link>
                            </span>
                            <br />
                          </Fragment>
                      </td>
                      <td>
                        {moment(d?.date).format("DD/MM/YYYY")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        <State.Errored message="We didn't find any data" />
      )}
	<Footer />
    </>
  )
}
