import State from 'components/common/State'
import Footer from 'components/corporate/Footer'
import { API_BASE } from 'helpers/pageUtils'
import React from 'react'
import DefaultAltTextSetter from 'components/common/DefaultAltTextSetter'

const AboutUs = ({ data }) => {
  if (Array.isArray(data)) {
    data = data[0]
  }
  if (!data || (Array.isArray(data) && !data.length)) {
    return (
      <>
        <State.Errored message="We didn't find any data" />
        <Footer />
      </>
    )
  }
  let content = data.content

  try {
    const data = JSON.parse(content)
    if (data && data.length && data[0]?.value) {
      content = data[0].value
    }
  } catch (e) {
    //
  }
  return (
    <>
      <div
        className={data?.cover_photo === null? '' : 'aboutbanner' }
        style={{
          backgroundImage: `url(${API_BASE}${data?.cover_photo?.file})`,
        }}
      >
        <h1>{data?.cover_photo === null? "" : data.title}</h1>
      </div>
      {content ? (
	    <div>
          <div
            className='container pb-3'
            dangerouslySetInnerHTML={{
              __html: content || '',
            }}
          />
		  <DefaultAltTextSetter data={content} />
		</div>
      ) : (
        <State.Errored message="We didn't find any data" />
      )}
      <Footer />
    </>
  )
}

export default AboutUs
