import State from 'components/common/State'
import { LocaleContext } from 'App'
import Footer from 'components/corporate/Footer'
import { API_BASE } from 'helpers/pageUtils'
import React, { useContext,Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useHistory } from 'react-router-dom'

export default function PressReleaseDetail({ data }) {
  const { t } = useTranslation('common')
  let history = useHistory();
  const lang = useContext(LocaleContext)
  if (!data) {
    return (
      <>
        <State.Errored message="We didn't find any data" />
        <Footer />
      </>
    )
  }
  return (
    <>
    <div className="container">
      {/* <div className='careerbg'>
        <h1>{t('corporate-header.career')}</h1>
      </div> */}
      {data ? (
        <>
        <h3 className="my-4 pressreleasetitle" dangerouslySetInnerHTML={{
            __html: (data && data?.english_title ? data?.english_title : data?.hindi_title ) || '',
            }}/>

        
        <h2 className="d-none" dangerouslySetInnerHTML={{
            __html: data?.date || '',
            }}/>
        
        <span
            dangerouslySetInnerHTML={{
            __html: (data && data?.body_english ? data?.body_english : data?.body_hindi ) || '',
            }}
        />
		<div className="d-flex justify-content-center">
        <button onClick={() => history.goBack()} className="btn btn-primary"><i className="fa fa-angle-left me-2" />{t('corporate-header.back')}</button>
          </div>
        </>
      ) : (
        <State.Errored message="We didn't find any data" />
      )}
	  </div>
	<Footer />
    </>
  )
}
