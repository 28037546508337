import { useEffect, useRef, useState } from "react";
import { FaceBook, Insta, KooIcon, Lang, LangArr, Profile, Twitter, Youtube } from "./SVG";
import { useHistory, Link } from "react-router-dom";
import ProfilePopup from "./ProfilePopup";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import LanguageModal from "components/common/LanguageModal";
import useSelectPrimary from "helpers/useSelectPrimary";

const langList = [{ label: "हिं", value: "hi", text: "हिंदी" },
{ label: "En", value: "en", text: "English" }]
const social = [{ id: 1, name: "Facebook", icon: FaceBook, url: "https://www.facebook.com/officialdmrc/" }, { id: 2, name: "Instagram", icon: Insta, url: "https://www.instagram.com/officialdmrc/?hl=en" }, { id: 3, name: "Twitter", icon: Twitter, url: "https://twitter.com/OfficialDMRC" }, { id: 4, name: "Koo App", icon: KooIcon, url: "https://www.kooapp.com/profile/OfficialDMRC" }
    // , { id: 5, name: "Youtube", icon: Youtube, url: "/"}
]

const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <LangArr />
            </components.DropdownIndicator>
        )
    );
};

const MainHeader = ({ user, isLogin, setIsLogin, setUser, lang, updateLang }) => {
    const [profile, setProfile] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem('lang') || 'en')
    const [langFocus, setLangFocus] = useState(false);
    const [showPopup, setShowPopup] = useState(false)
    const [pendingAction, setPendingAction] = useState(null)
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light')
    const ref = useRef();
    const history = useHistory();
    const { t } = useTranslation('common')
    let styles = useSelectPrimary(theme, "pdpb");

    useEffect(() => {
        setTheme(localStorage.getItem('theme'))
    }, [theme]);

    const handleLangSwitch = (event) => {
        const selectedLang = event?.value
        setPendingAction(() => async () => {
            let url = await window.location.pathname?.replace(lang, selectedLang)
            history.push(url)
            updateLang(selectedLang)
            setLanguage(selectedLang) // Update local state
        })
        setShowPopup(true)
        document.documentElement.lang = selectedLang; // Update the <html> lang attribute
    }

    const handleConfirm = () => {
        if (pendingAction) pendingAction()
        setShowPopup(false)
    }

    const handleCancel = () => {
        setPendingAction(null)
        setShowPopup(false)
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setProfile(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    const handleNavigate = () => {
        if (!!user && isLogin) {
            setProfile(true)
        } else {
            history.push(`/${lang}/new-corporate/login`)
        }
    }

    return (
        <>
            {showPopup &&
                <LanguageModal
                    handleConfirm={handleConfirm}
                    handleCancel={handleCancel}
                />
            }
            <div className='w-100 new-corporate'>
                <div className='w-100 main_header d-flex align-items-center justify-content-end'>
                    <div className="d-flex align-items-center h-100 sub_f_main_header">
                        <div className="title_section d-flex align-items-center h-100" >
                            <Link to={`/${lang}`}>{t('top-header.for-passenger')}</Link>
                            <span>|</span>
                            <Link to={`/${lang}/corporate`}>{t('top-header.for-corporate')}</Link>
                        </div>



                        <div className={`d-flex align-items-center language h-100 ${langFocus && "language-active"}`} aria-label={t("aria_label.lang")} title={t("aria_label.lang")}>
                            <Lang />
                            <Select options={[{ label: "हिं", value: "hi", text: "हिंदी" },
                            { label: "En", value: "en", text: "English" }]} styles={styles} className="!w-100 lang-select" onChange={(e) => handleLangSwitch(e)} value={!!language ? langList?.find((item) => item?.value === language) : { label: "En", value: "en", text: "English" }} isSearchable={false} components={{ DropdownIndicator }} onFocus={() => setLangFocus(true)} onBlur={() => setLangFocus(false)} />
                        </div>
                    </div>

                    <div className="d-flex align-items-center h-100 sub_s_main_header">
                        <div className="d-flex align-items-center h-100 social">
                            {Array.isArray(social) && social?.map((item, index) => (
                                <Link to={item?.url} key={index} className="social_icon" aria-label={item?.name} title={item?.name} onClick={() => setSelectSocial(item)}><item.icon /></Link>
                            ))}
                        </div>

                        <div className="login_title d-flex align-items-center h-100 position-relative " ref={ref}>
                            {!!user ?
                                <button className="d-flex align-items-center gap-2 login_title_btn" onClick={handleNavigate} >
                                    <Profile />
                                    <h1>{user?.first_name} {" "} {user?.last_name}</h1>
                                </button>
                                :
                                <button className="d-flex align-items-center gap-2 login_title_btn" onClick={handleNavigate} >
                                    <Profile />
                                    <h1>{t('top-header.login')}</h1>
                                </button>
                            }

                            {profile && <ProfilePopup user={user} setIsLogin={setIsLogin} setUser={setUser} setProfile={setProfile} />}
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default MainHeader;