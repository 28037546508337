import AboutUs from 'pages/corporate/AboutUs'
import Career from 'pages/corporate/Career'
import ArchivedCareer from 'pages/corporate/ArchivedCareer'
import PressRelease from 'pages/corporate/PressRelease'
import Academy from 'pages/corporate/Academy'
import PressReleaseDetail from 'pages/corporate/PressReleaseDetail'
import NetworkMap from 'pages/corporate/NetworkMap'
import Tender from 'pages/corporate/Tender'
import { useEffect, useState } from 'react'
import useWebSocket from 'react-use-websocket'
import News from 'pages/corporate/News'
import news_details from 'pages/corporate/NewDetail'

export const API_BASE =
  process.env.REACT_APP_API_BASE || ( window?.location?.pathname?.includes("new-corporate") ? 'https://metro-property-admin.stagemyapp.com' : 'https://metro-admin.stagemyapp.com')
export const API_ENDPOINT = `${API_BASE}/api/v2`

export const WS_STATION_UPDATES =
  'ws://metro.stagemyapp.com:8000/ws/station_data/'

export const WS_LINE_UPDATES =
  'ws://metro.stagemyapp.com:8000/ws/metro_line_data/'

export const states = {
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
}

const passengerPages = [
  '/map',
  '/station',
  '/line',
  '/tour-guide',
  '/what-we-are-doing',
  '/guidelines-for-passengers',
  '/recharge-your-smart-card-online',
  '/know-about-open-entry-gates',
  '/know-about-interchange-stations',
  '/faqs-related-to-travel',
  '/metro-stations-contact-numbers',
  '/passenger-information',
  '/facilities-for-women-passengers',
  '/faq',
  '/metro-stations-contact-numbers',
  '/miscellaneous',
  '/view-all',
  '/search',
  '/corporate-search',
  '/train-coach-run',
  '/train-frequency',
  '/theft-other-security-cases-report',
  '/metro-securities',
  '/commuters-guide',
  '/eartquake-secure',
  '/station-facilities',
  '/lost-found',
  '/lost-found-list',
  '/forms-guidelines-for-outsourced-employees',
  '/atm-details',
  '/dmrc-card-top-up',
  '/unclaimed-vehicle-parked-in-metro-station-parking',
  '/cisf-security-officer',
  '/smart-card-recharge-facility-through-icici-banks-o',
  '/information-regarding-tokencards-and-recharge',
  '/video-links',
  '/list-of-majority-stations',
  '/rti-sub',
  '/details-of-appellate',
  '/disclaimer',
  '/network',
  '/facilities',
  '/facilities-for-differently-abled-passengers',
  '/parking-bicycle-facilities',
  '/airport-express-line',
  '/rapid-metro',
  '/journey-planner-and-fares',
  '/feeder-buses',
  '/privacy-policy',
  '/parking-details',
  '/blacklisted-agencies',
  '/web-information-manager',
  '/penalties',
  '/feedback',
  '/contact-us',
  '/ticket',
  '/security-dmrc',
   '/smart-card',
  '/tourist-card',
  '/token',
  '/prohibited',
  '/airport-facilities',
  '/airport-metro-additional-services',
  '/rate-list',
  '/property-business',
  '/travel-advisory',
  '/screen-reader-access',
  '/last-mile-connectivity',
  '/e-rickshaw-services',
  '/cycle-sharing-services',
  '/metro-feeder-buses-service-availability',
  '/metro-feeder-buses-routes',
  '/metro-feeder-buses-fare',
  '/metro-feeder-buses-timings',
  '/e-rickshaw-service-availability',
  '/e-rickshaw-stations-with-e-rickshaw-facility',
  '/e-rickshaw-fare',
  '/e-rickshaw-timings',
  '/e-scooter-service-availability',
  '/e-scooter-stations-with-e-scooter-facility',
  '/e-scooter-fare',
  '/e-scooter-timings',
  '/cycle-sharing-service-availability',
  '/cycle-sharing-stations-with-pedal-cycle-facility',
  '/cycle-sharing-stations-with-battery-operated-cycle',
  '/cycle-sharing-fare',
  '/cycle-sharing-timings',
  '/cab-aggregator-service-availability',
  '/cab-aggregator-stations-with-cab-aggregator-servic',
  '/cab-aggregator-fare',
  '/cab-aggregator-timings',
  '/auto-rickshaw-e-booth-service-availability',
  '/auto-rickshaw-stations-with-auto-aggregator',
  '/auto-rickshaw-e-booth-fare',
  '/auto-rickshaw-e-booth-timings',
  '/redressal-of-public-grievances-in-dmrc',
  '/fare',
  '/fare-for-airport-express-line',
  '/app-based-qr-ticketing',
  '/paper-qr-ticketing',
  '/national-common-mobility-card',
  '/list-of-metro-police-stations',
  '/safety-during-travel',
  '/emergency-evacuation-plan',
  '/network_map',
  '/unclaimed-vehicle',
  '/kiosk',
  '/passenger-kiosk',
  '/museum-kiosk',
  '/women-day',
  '/newsletter-page',
  '/the-network-map',
  '/electrical-vehicle-fare',
  '/electrical-vehicle-public-charging-and-battery-swa',
  '/know-your-carbon-savings',
  '/carbonlite-metro-travel',
  '/copyright-policy',
  '/content-contribution-moderation-approval-policy',
  '/content-archival-policy',
  '/content-review-policy',
  '/hyperlinking-policy',
  '/terms-and-conditions',
  '/website-monitoring-plan',
  '/contingency-management-plan',
  '/security-policy'
  
  
]
export const ensurePassenger = (currentPath, lang) =>
  passengerPages.some((p) => currentPath.startsWith(`/${lang + p}`)) || currentPath === `/${lang}`

export function useStationUpdates() {
  /* const { lastJsonMessage: data } = useWebSocket(WS_STATION_UPDATES, {
    shouldReconnect: (_e) => true,
  })
  return data */
}

export function useLineUpdates() {
  /* const { lastJsonMessage: data } = useWebSocket(WS_LINE_UPDATES, {
    shouldReconnect: (_e) => true,
  })
  return data */
}

export const merryGoRound = (i) =>
  parseInt(
    `${((typeof i === 'string' ? parseInt(i) : i) + 3e1) * 1e4}`
  ).toString(3e1 + 6)

export const reverseMerryGoRound = (id) => parseInt(id, 3e1 + 6) / 1e4 - 3e1

export function useSimpleFetch(lang, slug, condition = true) {
  const [data, setData] = useState(null)
  const [state, setState] = useState(states.LOADING)
  useEffect(() => {
    const fetchData = async () => {
      if (slug) {
        //console.log('%%hi1',slug)
        const headers = new window.Headers()
        headers.set('Content-Type', 'application/json')
        try {
          const res = await window.fetch(`${API_ENDPOINT}/${lang}/${slug}`, {
            headers,
          })
          const json = await res.json()
          setData(json)
          setState(states.SUCCESS)
        } catch (error) {
          console.log({ error })
          setState(states.ERROR)
        }
      } else {
        setState(states.ERROR)
      }
    }
    if (condition) {
      fetchData()
    } else {
      setData(null)
      setState(states.SUCCESS)
    }
  }, [lang, slug])

  const result = [data, state]
  result.data = data
  result.state = state
  return result
}

export const useFetch = (lang, slug, shouldFix = true, isCorporate = true) => {
  const [data, setData] = useState(null)
  const [currentPage, setCurrentPage] = useState(null)
  const [state, setState] = useState(states.LOADING)
  useEffect(() => {
    const fetchData = async () => {
      let correctSlug = slug
      if (slug && shouldFix) {
        correctSlug = await fixSlug(slug, lang)
        if (
          isCorporate &&
          !['menus', 'career','pressrelease','pressrelease_details','news','news_details', 'archived_career'].includes(slug) &&
          !slug.split('/').includes('corporate')
        )
          correctSlug = `corporate/${correctSlug}`
         // console.log("###correctSlug",correctSlug)
          if(correctSlug.startsWith('corporate/pressrelease_details')){
            correctSlug=correctSlug.replace("corporate/pressrelease_details","pressrelease_details")
            //console.log("###slug1",correctSlug);
          }else if(correctSlug.startsWith('corporate/in-the-news')){
            correctSlug=correctSlug.replace("corporate/in-the-news","news")
            console.log("!!!slug1",correctSlug);

          }else if(correctSlug.startsWith('corporate/news_details')){
            correctSlug=correctSlug.replace("corporate/news_details","news_details")
            console.log("!!!slug1",correctSlug);

          }
          console.log("!!!slug",correctSlug);
      }
      if (slug && isTenderSlug(slug)) {
        correctSlug = ensureEncryptyed(slug)
        //console.log("!!!correctSlug",correctSlug)
      }
      if (slug) {
        //console.log("!!!corporate123",slug)
        const headers = new window.Headers()
        headers.set('Content-Type', 'application/json')
		if(correctSlug && correctSlug == "../../corporate") {
		  window.location.href = "/corporate";
		} else {
			try {
			  console.log("!!!api",`${API_ENDPOINT}/${lang}/${correctSlug}`)
			  let url = `${API_ENDPOINT}/${lang}/${correctSlug}`;

			  // Conditionally add a trailing slash if correctSlug is "something"
			  if (correctSlug === "tenderscategory") {
			    url += "/";
			  }
			  const res = await window.fetch(
				url,
				{
				  headers,
				}
			  )
			  const json = await res.json()
			  setData(json)
			  //console.log("!!!corporate",json)
			  setCurrentPage(slug)
			  setState(states.SUCCESS)
			} catch (error) {
			  console.log({ error })
			  setState(states.ERROR)
			}
		}
      } else {
        setState(states.ERROR)
      }
    }
    fetchData()
  }, [lang, slug, shouldFix, isCorporate])

  return { data, state, currentPage }
}

const isTenderSlug = (slug) => slug.split('/').includes('tenders_by_category')

const ensureEncryptyed = (slug) => {
  const parts = slug.split('/')
  parts[1] = reverseMerryGoRound(parts[1])
  return parts.join('/')
}

const slugMap = {
  about: AboutUs,
  career: Career,
  archived_career: ArchivedCareer,
  academy : Academy,
  pressrelease : PressRelease,
  pressrelease_details : PressReleaseDetail,
  news: News,
  news_details: news_details,
  network_map : NetworkMap,
  tenders_by_category: Tender,
}

export const compForSlug = (slug) => {
  if (!slug) {
    return
  }
  let comp = AboutUs
  Object.keys(slugMap).forEach((key) => {
    if (slug.startsWith(key)) {
      comp = slugMap[key]
    }
  })
  return comp
}

const ensureMenues = async (lang) => {
  if (!window.localStorage.getItem(`menus_${lang}`)) {
    const headers = new window.Headers()
    headers.set('Content-Type', 'application/json')
    try {
      const res = await window.fetch(`${API_ENDPOINT}/${lang}/menus`, {
        headers,
      })
      const json = await res.json()
      //console.log("!!!good",json)
      window.localStorage.setItem(`menus_${lang}`, JSON.stringify(json))
    } catch (error) {
      console.log({ error })
    }
  }
}

export const getRedirect = async (lang, currentSlug) => {
  await ensureMenues(lang)
  const menuData = JSON.parse(window.localStorage.getItem(`menus_${lang}`))
  let isTop = false
  const possibleTree = menuData.filter((route) => {
    if (route.page_slug === currentSlug) {
      isTop = true
      return true
    }
    if (route.subRoutes && !Array.isArray(route.subRoutes)) {
      return false
    }
    return route?.subRoutes.some(
      (subRoute) => subRoute.page_slug === currentSlug
    )
  })

  if (possibleTree.length && isTop) {
    return `/pages/${lang}/${possibleTree[0].page_slug}`
  }
  if (!isTop && possibleTree.length) {
    return `/pages/${lang}/${possibleTree[0].page_slug}/${currentSlug}`
  }
  return ''
}

export const fixSlug = async (slug, lang) => {
  await ensureMenues(lang)
  const menuData = JSON.parse(window.localStorage.getItem(`menus_${lang}`))
  const possibleRoutes = menuData
    .flatMap((route) => [route, ...route?.subRoutes])
    .filter(
      (route) =>
        route.type_of === 'url' &&
        route.url &&
        route.url
          .split('/')
          .filter((s) => s && s !== lang)
          .join('/') === slug
    )
  if (possibleRoutes.length) {
    return possibleRoutes[0].url
      .split('/')
      .filter((s) => s && s !== lang)
      .join('/')
  }
  const parts = slug.split('/').filter((s) => s)
  return parts.join('/')
}

export const parseLink = (data) => {
  if (
    data.url &&
    data.url
      .split('/')
      .filter((u) => u)
      .includes('tenders')
  ) {
    return `tenders_by_category/${merryGoRound(2)}`
  }
  if (data.type_of === 'page') {
    return data.page_slug || ''
  }
  if (data.type_of === 'url') {
    const url = data.url
    //console.log("!!!url",url)
    if(url.startsWith('/en//corporate')){
      const parts = url.replace("/en//corporate","/corporate")
        // const parts =url.split('/').filter((u)=> u).slice(3).join('/') || ''
        console.log("!!!part",parts)
        return parts
      

    }else{
      const parts = url.split('/').filter((u) => u)
      return parts.slice(1).join('/') || ''

    }
    // if (url) {
    //   const parts = url.split('/').filter((u) => u)
    //   return parts.slice(1).join('/') || ''
    // }
  }
  return ''
}

export const parseSubLink = (data, subData) => {
  const url = []
  if (data.type_of === 'page' && data.page_slug) {
    url.push(data.page_slug)
  }
  if (subData.type_of === 'page' && subData.page_slug) {
    url.push(subData.page_slug)
  }
  if (url.length && url.reverse()[0] !== data.page_slug) {
    return url.join('/')
  }
  if (subData.type_of === 'url') {
    const url = subData.url
    const parts = url
      .split('/')
      .filter((u) => u)
      .slice(1)
    if (parts[0] === 'tenders_by_category') {
      parts[1] = merryGoRound(parts[1])
    }
    return parts.length > 1 ? parts.join('/') : parts[0]
  }
  return ''
}

export const parseThirdLink = (data, subData, thirdData) => {
  const url = []
  if (data.type_of === 'page' && data.page_slug) {
    url.push(data.page_slug)
  }
  if (subData.type_of === 'page' && subData.page_slug) {
    url.push(subData.page_slug)
  }
  if (thirdData.type_of === 'page' && thirdData.page_slug) {
    url.push(thirdData.page_slug)
  }
  if (url.length) {
    return url.join('/')
  }
  if (subData.type_of === 'url') {
    const url = subData.url
    if (url) {
      const parts = url.split('/').filter((u) => u)
      return parts.slice(1).join('/') || ''
    }
  }
  return ''
}

export const isActive = (navRoute, currentPath) => {
  const normalized = currentPath
    .split('/')
    .filter((s) => s && !['pages', 'en', 'hi'].includes(s))
    .join('/')
  const route = parseLink(navRoute)
  const subRoutes = navRoute?.subRoutes?.map((r) => parseSubLink(navRoute, r))
  return (
    normalized &&
    (route === normalized ||
      (subRoutes && subRoutes?.length && subRoutes?.includes(normalized)))
  )
}

const normalizedTenderKeys = [
  'content',
  'sale_date',
  'last_submission_date',
  'opening_date',
  'inviting_authority',
]

export const fixTenderData = (data) => {
  if (Array.isArray(data) && data.length) {
    const tenders = data.map((d) =>
      Object.entries(d).reduce((prev, [key, val]) => {
        normalizedTenderKeys.forEach((k) => {
          if (key.startsWith(k)) {
            prev[k] = val
          }
        })
        return prev
      }, {})
    )
    return tenders
  }
  return []
}
