import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/carousel'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/tab'
import 'bootstrap/js/dist/tooltip'
import { ensurePassenger } from 'helpers/pageUtils'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import 'popper.js'
import { createContext, useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import './App.scss'
import './i18n'
import ReactGA from 'react-ga';
import React, { Suspense, lazy } from 'react';
import loadingImg from 'assets/dmrc.gif'
import packageJson from '../package.json';
import { routes } from 'helpers/routes'
import { useHistory } from 'react-router-dom'

const TopHeader = lazy(() => import('components/common/TopHeader'));
const MainHeader = lazy(() => import('components/newCorporate/MainHeader'));
// corporate pages
const Pages = lazy(() => import('pages/Pages'))
// passenger pages
const NotFound = lazy(() => import('pages/passenger/NotFound'))
// consultancy-services
const ConsultancyServicesPages = lazy(() => import('pages/consultancyServices/Pages'))


export const LocaleContext = createContext()
export const UpdateContext = createContext()

const App = () => {
	ReactGA.initialize('UA-102628-11');
	ReactGA.pageview(window.location.pathname + window.location.search);
	const { i18n } = useTranslation()
	const { pathname: currentPath } = useLocation()
	const [lang, setLang] = useState(window.localStorage?.getItem('lang') || 'en')
	const isPassenger = ensurePassenger(currentPath, lang)
	const [isLogin, setIsLogin] = useState(null);
	const [routesData, setRoutesData] = useState(null);
	const [user, setUser] = useState(!!JSON.parse(localStorage.getItem("userdetail")) ? JSON.parse(localStorage.getItem("userdetail")) : null)
	const [redirectPath, setRedirectPath] = useState([])
	const [font, setFont] = useState(16)
	const [theme, switchTheme] = useState(localStorage.getItem('theme') || 'light')
	const pageRef = useRef()
	const availHeight = window.screen.availHeight;
	const history = useHistory();

	useEffect(() => {
		let version = localStorage.getItem('version');
		if (version != packageJson.version) {
			if ('caches' in window) {
				caches.keys().then((names) => {
					// Delete all the cache files
					names.forEach(name => {
						caches.delete(name);
					})
				});
				// Makes sure the page reloads. Changes are only visible after you refresh.
				window.location.reload(true);
			}
			localStorage.clear();
			localStorage.setItem('version', packageJson.version);
		}
	}, [])

	const updateLang = (l) => {
		const current = currentPath.split('/')
		if (current[1] === 'pages' && current[2] !== l) {
			pageRef.current.onLangChange(current[2], l)
		}
		setLang(l)
		if (i18n.language !== l) {
			i18n.changeLanguage(l)
		}
		window.localStorage.setItem('lang', l)
	}

	const current = currentPath.split('/')

	if (current[1] === 'pages' && current[2] !== lang) {
		updateLang(current[2])
	}

	const pageTitles = {
		en: "Welcome to Delhi Metro Rail Corporation(DMRC) | Official Website",
		hi: "दिल्ली मेट्रो रेल कॉर्पोरेशन (डीएमआरसी) में आपका स्वागत है | आधिकारिक वेबसाइट",
	};

	// auth
	useEffect(() => {
		let login = null
		login = !!JSON.parse(localStorage.getItem("sessionTimeout")) ? true : false

		setIsLogin(login);

		if (!window.location.pathname?.includes("/en") && !window.location.pathname?.includes("/hi")) {
			history.push(window.location.pathname === "/" ? `/${lang}` : `/${lang}${window.location.pathname}`)
		}

		if (window.location.pathname?.includes("/en")) {
			updateLang("en")
			document.title = pageTitles[lang] || "Welcome to Delhi Metro Rail Corporation(DMRC) | Official Website";
		}

		if (window.location.pathname?.includes("/hi")) {
			updateLang("hi")
			document.title = pageTitles[lang] || "Welcome to Delhi Metro Rail Corporation(DMRC) | Official Website";
		}
	}, []);

	useEffect(() => {
		let arr = routes(isLogin, isPassenger)
		setRoutesData(arr)
	}, [isLogin, isPassenger])

	return (
		<div className={isPassenger ? 'passenger' : 'corporate'}>
			<div id='theme-2024' className={`theme-2024 ${theme}`}> {/* Added theme-2024 at the top of every page */}
				<Suspense fallback={<div style={{ textAlign: 'center', margin: 'auto', minHeight: "100vh" }}><img style={{ width: '400px', marginTop: '10%' }} src={loadingImg} /></div>}>
					{(isLogin != null && routesData !== null) && <LocaleContext.Provider value={lang}>
						{(!window.location.pathname?.includes("/new-corporate") && !window.location.pathname?.includes("/consultancy-services")) && availHeight <= 3000 ?
							<TopHeader lang={lang} updateLang={updateLang} font={font} setFont={setFont} switchTheme={switchTheme} />
							: window.location.pathname?.includes("/new-corporate") ?
								<MainHeader user={user} isLogin={isLogin} setIsLogin={setIsLogin} setUser={setUser} lang={lang} updateLang={updateLang} />
								:
								''
						}
						<Switch>
							{redirectPath.length && (
								<Redirect from={redirectPath[0]} to={redirectPath[1]} />
							)}
							{Array?.isArray(routesData) && routesData?.map((item, index) => (
								<Route path={`/:lang${item?.path}`} exact key={index}>
									{window.location.pathname?.includes("/new-corporate/login") ? <item.component setIsLogin={setIsLogin} setUser={setUser} /> : window.location.pathname?.includes("/consultancy-services") ? <item.component updateLang={updateLang} font={font} setFont={setFont} /> : <item.component />}
								</Route>
							))}
							<Route path='/pages/:lang/:slug*'>
								<Pages ref={pageRef} onRedirect={setRedirectPath} />
							</Route>
							<Route path='/new-corporate/pages/:lang/:slug*'>
								<Pages ref={pageRef} onRedirect={setRedirectPath} />
							</Route>
							<Route path='/:lang/consultancy-services/pages/:slug*'>
								<ConsultancyServicesPages updateLang={updateLang} font={font} setFont={setFont} />
							</Route>
							<Route path='*'>
								<NotFound updateLang={updateLang} font={font} setFont={setFont} />
							</Route>
						</Switch>
					</LocaleContext.Provider>}
				</Suspense>
			</div>
		</div >
	)
}

export default App
