export const useSelectPrimary = (theme, type) => {
  let styles = {
    menu: (provided) => ({
      ...provided,
      zIndex: "20 !important",
      borderRadius: "5px",
      background: "#fff",
      boxShadow: "0px 10px 32px -4px rgba(24, 39, 75, 0.10), 0px 6px 14px -6px rgba(24, 39, 75, 0.12)",
      top: "90%"
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: "600",
      color: state.isSelected ? "#F00" : state.isFocused ? "#5A5A5A" : "#5A5A5A",
      backgroundColor: state.isSelected ? "#EDEDED" : state.isFocused ? "#EDEDED" : "#fff",
      cursor: "pointer",
      border: "none",
      "&:hover": {
        backgroundColor: state.isDisabled ? "normal" : "#EDEDED",
        border: "none",
        color: state.isSelected ? "#F00" : "#686868",
      },
      padding: "6px 12px"
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: `${theme === "dark" ? `${type === "main" ? "#DEDEDE" : "white"}` : `${type === "main" ? "#6B6B6B" : "white"}`}`,
      fontWeight: "600",
      margin: 0,
      width: "16px",
    }),
    menuList: (provided, state) => ({
      ...provided,
      borderRadius: "5px"
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingRight: "6px",
      paddingLeft: "6px"
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? `${theme === "dark" ? "#DEDEDE" : "#1A1F36"}` : `${theme === "dark" ? "#DEDEDE" : "#1A1F36"}`,
      fontWeight: "600",
      "&:hover": {
        color: state.isFocused ? `${theme === "dark" ? "#DEDEDE" : "#1A1F36"}` : `${theme === "dark" ? "#DEDEDE" : "#1A1F36"}`,
      },
      padding: 0,
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "0" : "0",
      backgroundColor: state.isFocused ? "none" : "none",
      borderRadius: state.isFocused ? "6px" : "6px",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        border: state.isFocused ? "0" : "0",
      },
      cursor: "pointer",
      minHeight: '18px',
    }),
    input: (provided, state) => ({
      ...provided,
      color: "#1A1F36",
      fontWeight: "400"
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#1A1F36",
      fontWeight: "600",
    }),
  };

  return styles;
};

export default useSelectPrimary;