import State from 'components/common/State'
import Footer from 'components/corporate/Footer'
import { API_BASE } from 'helpers/pageUtils'
import React, { useEffect, useState } from 'react'
import DefaultAltTextSetter from 'components/common/DefaultAltTextSetter'
import ExternalModal from 'components/common/ExternalModal.js'

const AboutUs = ({ data }) => {
	const [showModal, setShowModal] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState("");
	
  if (Array.isArray(data)) {
    data = data[0]
  }
  if (!data || (Array.isArray(data) && !data.length)) {
    return (
      <>
        <State.Errored message="We didn't find any data" />
        <Footer />
      </>
    )
  }
  let content = data.content

  try {
    const data = JSON.parse(content)
    if (data && data.length && data[0]?.value) {
      content = data[0].value
    }
  } catch (e) {
    //
  }
	
	const handleLinkClick = (event) => {
		let target = event.target;

		if (target.tagName !== "A") {
			target = target.closest("a");
		}

		if (target && target.href.includes("youtube.com")) {
			event.preventDefault(); // Prevent default navigation
			event.stopPropagation(); // Stop event from propagating
			setYoutubeLink(target.href); // Store the YouTube link
			setShowModal(true); // Show the confirmation modal
		} else if (target && target.href.includes("https://")) {
			event.preventDefault(); // Prevent default navigation
			event.stopPropagation(); // Stop event from propagating
			setYoutubeLink(target.href); // Store the YouTube link
			setShowModal(true); // Show the confirmation modal
		}
	};

  const handleConfirm = () => {
    window.open(youtubeLink, "_blank"); // Open the YouTube link in a new tab
    setShowModal(false); // Close the modal
  };
	
	const handleCancel = () => {
		setShowModal(false)
	};

  useEffect(() => {
    const container = document.querySelector(".static-page");

    if (container) {
      container.addEventListener("click", handleLinkClick);
    }

    // Cleanup event listener on unmount
    return () => {
      if (container) {
        container.removeEventListener("click", handleLinkClick);
      }
    };
  }, []);

  useEffect(() => {
    if (showPopup) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "auto";
    }
}, [showPopup]);
	
  return (
    <>
			{showModal ?
				<ExternalModal
					handleConfirm={handleConfirm}
					handleCancel={handleCancel}
				/>
			:
				''
			}
      <div
        className={data?.cover_photo === null? '' : 'aboutbanner' }
        style={{
          backgroundImage: `url(${API_BASE}${data?.cover_photo?.file})`,
        }}
      >
        <h1>{data?.cover_photo === null? "" : data.title}</h1>
      </div>
      {content ? (
	    <div>
          <div
            className='container pb-3 static-page'
            dangerouslySetInnerHTML={{
              __html: content || '',
            }}
          />
		  <DefaultAltTextSetter data={content} />
		</div>
      ) : (
        <State.Errored message="We didn't find any data" />
      )}
      <Footer />
    </>
  )
}

export default AboutUs
