import { useHistory } from 'react-router-dom';
import { ProfileIcon } from './SVG'
import { API_BASE } from 'helpers/pageUtils';
import { useContext } from 'react';
import { LocaleContext } from 'App';
import { useTranslation } from 'react-i18next';

const ProfilePopup = ({ user, setIsLogin, setUser, setProfile }) => {
    const history = useHistory();
    const lang = useContext(LocaleContext)
    const { t } = useTranslation('common')

    const handleLogoutApi = async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${user?.auth_token}`);

            const requestOptions = {
                headers: myHeaders
            };
            const res = await window.fetch(`${API_BASE}/api/v2/${lang}/logout/`,
                requestOptions
            )
            if (res && res?.status === 200) {
                return true;
            }
        } catch (error) {
            return false;
            console.error("error while logout", error);
        }
    }

    const handleLogout = async () => {
        const success = await handleLogoutApi();
        if (success) {
            setIsLogin(false)
            setUser(null)
            localStorage.clear();
            history.push(`/${lang}/new-corporate`)
        }
    }

    const handleProfile = async () => {
        history.push(`/${lang}/new-corporate/my-account`);
    }

    const handleProperty = async () => {
        history.push(`/${lang}/new-corporate/my-properties`);
    }

    return (
        <div className='position-absolute profile-popup'>
            <div className='profile d-flex align-items-center' onClick={handleProfile}>
                <ProfileIcon />
                <div className='d-flex flex-column '>
                    <h3>{user?.first_name} {" "} {user?.last_name}</h3>
                    <p>{user?.email}</p>
                </div>
            </div>

            <button className='d-flex align-items-center w-100 profile-popup-btn profile-popup-first-btn'  onClick={() => { setProfile(false); handleProperty(); }}>
                <h4>{t('top-header.enquired-properties')}</h4>
            </button>

            <button className='d-flex align-items-center w-100 profile-popup-btn' onClick={() => { setProfile(false); handleLogout(); }} onBlur={() => setProfile(false)}>
                <h5>{t('top-header.logout')}</h5>
            </button>
        </div>
    );
};

export default ProfilePopup;