import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import data from './data'

i18n.use(initReactI18next).init({
  resources: data,
  lng: window.localStorage?.getItem('lang') || 'en',
  detection: {
    order: ['localStorage', 'path'],
  },
  interpolation: {
    escapeValue: false,
  },
})
export default i18n
