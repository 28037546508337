import State from 'components/common/State'
import Footer from 'components/corporate/Footer'
import { API_BASE, API_ENDPOINT } from 'helpers/pageUtils'
import React, { Fragment, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LocaleContext } from 'App'
import Pagination from '@material-ui/lab/Pagination';
import CareerEnglishImage from '../../assets/Public-Notice-for-Web-English-21102020.jpg'
import CareerHindiImage from '../../assets/Public-Notice-for-Web-Hindi-21102020.jpg'
import moment from 'moment'

export default function Career({ data }) {
  const { t } = useTranslation('common')
  const lang = useContext(LocaleContext)
  const [newdata, setData] = useState(data)
  const [loader, setLoader] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const handlePageChange = async (event,value) => {
	event.preventDefault()
    //setData(null)
    if (value) {
      const headers = new window.Headers()
      headers.set('Content-Type', 'application/json')
      const fetchOpts = { headers }
      try {
		setLoader(true)
        const res = await window.fetch(
          `${API_ENDPOINT}/${lang}/career/?page=${value}`,
          fetchOpts
        )
        const json = await res.json()
        setData(json)
        setCurrentPage(value)
		setLoader(false)
      } catch (error) {
        console.log({ error })
		setLoader(false)
      }
    }
  }
  const handleClick = () => {
	document.getElementById('tender').style.display = "none";
  }
  
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        handleClick();
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);
if (!newdata || !data) {
    return (
      <>
        <State.Errored message="We didn't find any data" />
        <Footer />
      </>
    )
  }
  return (
    <>
      <div className='careerbg'>
        <h1>{t('corporate-header.career')}</h1>
      </div>
	  <div id='tender' style={{display: 'block'}} className='modal advanced-search'>
        <div className='modal-dialog careerpopup modal-dialog-centered'>
			<div className='modal-content'>
			  <div className='circle-close-btn'>
				<button
				  id="buttonDismiss1"
				  className='btn-close '
				  style={{top: 15, right: 12}}
				  data-bs-dismiss='modal'
				  aria-label='Close'
				  onClick={handleClick}
				/>
			  </div>
			  <div className='modal-body'>
				<p><img src={CareerEnglishImage} /></p>
				<p><img src={CareerHindiImage} /></p>
			  </div>
			</div>
		</div>
	  </div>
      {newdata?.count || data?.count ? (
        <>
          {Array.isArray(data?.results) &&
          data?.results?.length ? (
            <div className='table-responsive container pb-3'>
			  <p>
			    <span style={{color: 'blue'}}>Apply online for engagement for O & M work of MML-3/MMRCL, at Mumbai</span>
				<a
				  href="https://hiring.delhimetrorail.com/"
				  className="btn btn-primary blink_me"
				  target="_blank"
				  style={{marginLeft: '10px', padding: '3px 10px', fontSize: '12px'}}
				>
				  Apply Online
				</a>
			  </p>
              <table className='table table-bordered careertable'>
                <thead>
                  <tr>
                    <th>{t('career-section.table-h1')}</th>
                    <th>{t('career-section.table-h2')}</th>
                    <th>{t('career-section.table-h4')}</th>
                    <th>{t('career-section.table-h5')}</th>
                    <th>{t('career-section.table-h3')}</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPage && currentPage > 1 ?
				    newdata?.results?.map((d, i) => (
                    <tr key={i}>
                      <td>{d?.advt_no}</td>
                      <td>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: d?.post_advertised || '',
                          }}
                        />
                      </td>
					  <td>{d?.issue_date && moment(d?.issue_date).format("DD/MM/yyy")}</td>
					  <td>{d?.closing_date && moment(d?.closing_date).format("DD/MM/yyy")}</td>
                      <td>
                        {d?.documents &&
                          <Fragment>
                            <span dangerouslySetInnerHTML={{__html: d?.documents || ''}} />
                            <br />
                          </Fragment>
                        }
                      </td>
                    </tr>
                  ))
				  :
				    data?.results?.map((d, i) => (
                    <tr key={i}>
                      <td>{d?.advt_no}</td>
                      <td>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: d?.post_advertised || '',
                          }}
                        />
                      </td>
					  <td>{d?.issue_date && moment(d?.issue_date).format("DD/MM/yyy")}</td>
					  <td>{d?.closing_date && moment(d?.closing_date).format("DD/MM/yyy")}</td>
                      <td>
                        {d?.documents &&
                          <Fragment>
                            <span dangerouslySetInnerHTML={{__html: d?.documents || ''}} />
                            <br />
                          </Fragment>
                        }
                      </td>
                    </tr>
                  ))
				  }
                </tbody>
              </table>
			  <div className="pagination">
				<Pagination
				  className="my-3"
				  count={Math.round(newdata?.count/10) || Math.round(data?.count/10)}
				  page={currentPage}
				  variant="outlined"
				  shape="rounded"
				  onChange={handlePageChange}
				/>
			  </div>
            </div>
          ) : (
            ''
          )}
        </>
      ) : loader ? (
        <State.Loading />
      ) : (
        <State.Errored message="We didn't find any data" />
      )}
	<Footer />
    </>
  )
}
