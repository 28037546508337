import State from 'components/common/State'
import { LocaleContext } from 'App'
import Footer from 'components/corporate/Footer'
import { API_BASE,states, useSimpleFetch } from 'helpers/pageUtils'
import React, { useContext,Fragment,useState,useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment'
import NoResultFound from 'components/common/NoResultFound'
// import Pagination from '@mui/material/Pagination';
// import Stack from '@mui/material/Stack';


export default function News({ data }) {
  const { t } = useTranslation('common')
  const lang = useContext(LocaleContext)
  const [addingYear, setYear] = useState('')
  const [addingMonth, setMonth] = useState('')
  const [addMonth, setMonthType] = useState(new Date().getMonth() + 1)
  const [addYear, setYearType] = useState(new Date().getFullYear() )
  const [searchData, setSearchData] = useState([])
  //const [page, setPage] = useState(1)
  //const [data, setData] = useState([])
  const [searchResult, searchState] = useSimpleFetch(
    lang,
    `news/?month=${addingMonth}&year=${addingYear}`,
    (!!addingYear)
  )
  useEffect(() => {
    if (addYear || addMonth) {
      setSearchData(searchResult)
      console.log("!!!searching data",searchData,searchResult)
      handleResult();
    } else {
      //console.log("@@@searching data1",searchData)
      setSearchData([])
    }
    //setData(searchResult)
  }, [searchResult|| addYear ||addMonth])
  console.log("!!!datasearch",searchData,searchResult)

  if (!data) {
    return (
      <>
        <State.Errored message="We didn't find any data" />
        <Footer />
      </>
    )
  }
  function handleSelectChange(e) {
    setMonthType(e.target.value);
    console.log(addMonth)
}
function handleYearChange(e) {
  setYearType(e.target.value);
  console.log(addYear)
}

function handleResult() {
  setMonth(addMonth)
  setYear(addYear)
}
let minOffset = 0, maxOffset = 4;
    let thisYear = (new Date()).getFullYear();
    let allYears = [];
    for(let x = 0; x <= maxOffset; x++) {
        allYears.push(thisYear - x)
    }
    const yearList = allYears.map((x) => {return(<option key={x}>{x}</option>)});
  return (
    <>
      <div className='pressbanner'>
        <h1>{t('corporate-header.news')}</h1>
      </div> 
      {data ||searchData ? (
        <>
          {Array.isArray(data || searchData) &&
          (data||searchData)?.length ? (
		  <div className="container pb-3">
		   <h2>{t('corporate-header.news')}</h2>
		   <div className="newsdate d-flex mb-3">
          <select
              defaultValue={addMonth}
              onChange={handleSelectChange}
              className="browser-default form-select form-select">
              {/* <option selected>select the month</option> */}
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
              
          </select>
          <select 
			  defaultValue={addYear}
              onChange={handleYearChange}
              className="browser-default form-select form-select mx-2">
              {yearList}
              
          </select>
          <button onClick={handleResult} className="btn btn-danger">Go!</button>
		  </div>
            <div className='table-responsive press-releasepage'>
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th style={{width:'130px'}}>Newspaper</th>
                    <th style={{width:'130px'}}>Date & place</th>
                    {/* <th>{t('career-section.table-h3')}</th> */}
                  </tr>
                </thead>
                <tbody>
                  {(addingMonth && addingYear) &&searchData&&Array.isArray(searchData)&& searchData.length ? searchData?.map((d, i) => (
                    <tr key={i}>
                      {/* <td>{d?.advt_no}</td> */}
                      <td>
                      <Fragment key={i}>
                            <span>
                            <Link to={`/pages/${lang}/news_details/${d.id}`}>
                                {d && d?.news_title ? d?.news_title : d?.news_title}
                              </Link>
                            </span>
                            <br />
                          </Fragment>
                      </td>
                      <td>
                        {d?.newspaper_name}
                      </td>
                      <td>
                        {moment(d?.news_date).format("DD/MM/YYYY")} & {d?.paper_place}
                      </td>
                    </tr>
                  )):addingMonth&&addingMonth!=="select the month" && addingYear&& addingYear!=="select the year"? <tr> <td colspan="3" className="text-center"><NoResultFound /></td></tr>
                  :
                  (data) ? data?.map((d, i) => (
                    <tr key={i}>
                      {/* <td>{d?.advt_no}</td> */}
                      <td>
                      <Fragment key={i}>
                            <span>
                            <Link to={`/pages/${lang}/news_details/${d.id}`}>
                                {d && d?.news_title ? d?.news_title : d?.news_title}
                              </Link>
                            </span>
                            <br />
                          </Fragment>
                      </td>
                      <td>
                        {d?.newspaper_name}
                      </td>
                      <td>
                        {moment(d?.news_date).format("DD/MM/YYYY")} & {d?.paper_place}
                      </td>
                    </tr>
                  ))
                  :
                  ""
                  }
                </tbody>
              </table>
            </div>
            
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        <State.Errored message="We didn't find any data" />
      )}
     
	<Footer />
    </>
  )
}
