import { LocaleContext } from 'App'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Logoatech from '../../assets/arohatech-logo.png'
import Niclogo from '../../assets/NIC-LOGO.png'
import XLogo from 'assets/x-logo.png'

const Footer = () => {
	const lang = useContext(LocaleContext)
  const { t } = useTranslation('common')
  return (
    <section className='fcc-section footer'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 col-lg-3'>
            <h3>{t('corporate-footer.contact-info')}</h3>
            <p>
              {t('footer.main-footer-address-line1')}<br/>
               {t('footer.main-footer-address-line2')}
              <br />
              {t('footer.main-footer-address-line3')}
            </p>
			</div>
          <div className='col-md-6 col-lg-6'>
            <h3>{t('corporate-footer.quick-links')}</h3>
            <div className='row'>
              <div className='col-md-6 '>
                <ul className='footerLinks'>
                  <li>
                    <Link to={lang === 'en' ? '/pages/en/dust-control-measures-implemented-at-sites' : '/pages/hi/dust-control-measures-implemented-at-sites'}>
                      {t('corporate-footer.quick-sub-links1')}
                    </Link>
                  </li>
                  <li className="d-none">
                    <a href='http://www.delhimetrorail.com/DMRC-Museum/index.html' target="_blank">{t('corporate-footer.quick-sub-links2')}</a>
                  </li>
                  <li>
                    <Link to={lang === 'en' ? '/pages/en/vendor-payment-details' : '/pages/hi/vendor-payment-details'}>{t('corporate-footer.quick-sub-links3')}</Link>
                  </li>
				 <li>
                    <a href='https://services.billdesk.com/MercOnline/web/delhi-metro/pay' target="_blank">{t('corporate-footer.cus-pay')}</a>
                  </li>
				 <li>
                    <Link to='/pages/en/list-of-md-awardees'>{t('corporate-footer.list-awardees')}</Link>
                  </li>
                  <li className="d-none">
                    <Link to='/pages/en/corporate/last-mile-services-limited'>{t('corporate-footer.quick-sub-links4')}</Link>
                  </li>
                  <li className="d-none">
                    <Link to='/pages/en/corporate/puppet-shows'>{t('corporate-footer.quick-sub-links5')}</Link>
                  </li>
                  
                </ul>
              </div>
              <div className='col-md-6'>
                <ul className='footerLinks'>
                  <li>
                    <Link to={lang === 'en' ? '/pages/en/public-notice' : '/pages/hi/public-notice'}>{t('corporate-footer.quick-sub-links8')}</Link>
                  </li>
                 <li className="d-none">
                    <Link to='/pages/en/corporate/facilities-for-superannuated-employees'>{t('corporate-footer.quick-sub-links10')}</Link>
                  </li>
				  <li>
                    <Link to={lang === 'en' ? '/pages/en/stores-procurement-information' : '/pages/hi/stores-procurement-information'}>{t('corporate-footer.quick-sub-links13')}</Link>
                  </li>
                  <li>
                    <Link to={lang === 'en' ? '/pages/en/contact-us' : '/pages/hi/contact-us'}>{t('corporate-footer.quick-sub-links11')}</Link>
                  </li>
                 <li className="d-none">
                    <Link to='/corporate'>{t('corporate-footer.quick-sub-links6')}</Link>
                  </li>
                  <li>
                    <a href='https://pgportal.gov.in/' target="_blank">{t('corporate-footer.quick-sub-links7')}</a>
                  </li>
                  <li>
                    <a href='/screen-reader-access'>{t('corporate-footer.help')}</a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-lg-2 d-none'>
            <h3>{t('footer.quick-contact')}</h3>
            <div className=''>
              <h6>{t('footer.quick-contact1')}</h6>
              <h3 className='mb-2'>{t('footer.quick-contact1-num')}</h3>
              <h6>{t('footer.quick-contact2')}</h6>
              <h3>{t('footer.quick-contact2-num')}</h3>
              <h6>{t('corporate-footer.follow-us')} :</h6>
              <ul className='sociallinks d-flex'>
                <li>
                  <a href='https://www.facebook.com/officialdmrc/' target="_blank">
                    <i className='fa fa-facebook' />
                  </a>
                </li>
                <li>
                  <a href='https://twitter.com/OfficialDMRC' target="_blank">
                    <img src={XLogo} alt="Twitter" width="32" height="32"/>
                  </a>
                </li>
                <li>
                 <a href='https://www.instagram.com/officialdmrc/?hl=en' target="_blank">
                    <i className='fa fa-instagram' />
                  </a>
                </li>
              </ul>
            </div>
          </div>
		   <div className='col-md-6 col-lg-3 footerpA'>
		  <a href={lang === 'en' ? '/pages/en/web-information-manager' : '/pages/hi/web-information-manager'}><p>{t('corporate-footer.web-info')}</p></a>
				<a href="https://www.arohatech.com/" target="_blank"><p><img src={Logoatech} alt="Arohatech - Web Portal Development Company and Mobile App Developer for Delhi Metro Rail Corporation (DMRC) India" title="Arohatech - Web Portal Development Company and Mobile App Developer for Delhi Metro Rail Corporation (DMRC) India" width="100"  style={{marginBottom:5}}/> <br/ >Designed, Developed & Maintained by Arohatech IT Services</p></a>
				<a href="https://www.nic.in/" target="_blank"><p><img src={Niclogo} alt="National Informatics Center" width="100" height="23" style={{marginBottom:5}}/> <br/ > Hosted by National Informatics Center</p></a>
        </div>
        </div>
		<div className="row">
			<div className="col-md-12 corp-copyright" style={{ backgroundColor: "none" }}>
			  © {new Date().getFullYear()} {t('corporate-footer.all-rights')}
			</div>
		</div>
	</div>
    </section>
  )
}

export default Footer
