import PropagateLoader from 'react-spinners/PropagateLoader'
import { useTranslation } from 'react-i18next'

const heights = {
  default: 'calc(100vh - 178px)',
  compact: 'auto',
  full: '100%',
}

const Loading = ({ message, height }) => {
  const { t } = useTranslation('passenger')
  if (!Object.keys(heights).includes(height)) {
    height = 'default'
  }
  return (
    <>
    <div
      className='container pb-3'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: heights[height],
        flexDirection: 'column',
      }}
    >
    {message && (
      <div 
        style={{
          marginTop:'80px',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          width: '88%',
          textAlign: 'center',
        }}
      >
        {t('home.loader-message')}
    </div>
      )}
      <PropagateLoader color='red' />
    </div>
    </>
  )
}

const Errored = ({ message, height }) => {
  if (!Object.keys(heights).includes(height)) {
    height = 'default'
  }
  return (
    <div
      className='container pb-3'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: heights[height],
      }}
    >
      <p>{message}</p>
    </div>
  )
}

export default {
  Loading,
  Errored,
}
