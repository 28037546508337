import { useEffect, useRef, useState } from "react";
import { FaceBook, Insta, KooIcon, Lang, LangArr, Profile, Twitter, Youtube } from "./SVG";
import { useHistory, Link } from "react-router-dom";
import ProfilePopup from "./ProfilePopup";
import { useTranslation } from "react-i18next";

const language = ["hi", "en"]
const social = [{ id: 1, name: "facebook", icon: FaceBook, url: "https://www.facebook.com/officialdmrc/" }, { id: 2, name: "Insta", icon: Insta, url: "https://www.instagram.com/officialdmrc/?hl=en" }, { id: 3, name: "twitter", icon: Twitter, url: "https://twitter.com/OfficialDMRC" }, { id: 4, name: "koo app", icon: KooIcon, url: "https://www.kooapp.com/profile/OfficialDMRC" }, { id: 5, name: "youtube", icon: Youtube, url: "/" }]

const MainHeader = ({ user, isLogin, setIsLogin, setUser, lang, updateLang }) => {
    const [openLang, setOpenLang] = useState(false)
    const [profile, setProfile] = useState(false);
    const ref = useRef();
    const ref1 = useRef();
    const history = useHistory();
    const { t } = useTranslation('common')

    const handleLangSwitch = (l) => {
        updateLang(l)
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setProfile(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref1.current && !ref1.current.contains(event.target)) {
                setOpenLang(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref1]);

    const handleNavigate = () => {
        if (!!user && isLogin) {
            setProfile(!profile)
        } else {
            history.push("/new-corporate/login")
        }
    }

    return (
        <div className='w-100 new-corporate'>
            <div className='w-100 main_header d-flex align-items-center justify-content-end'>
                <div className="d-flex align-items-center h-100 sub_f_main_header">
                    <div className="title_section d-flex align-items-center h-100" >
                        <Link to='/'>{t('top-header.for-passenger')}</Link>
                        <span>|</span>
                        <Link to='/corporate'>{t('top-header.for-corporate')}</Link>
                    </div>
                    <div className="position-relative d-flex align-items-center language h-100" ref={ref1} onClick={() => setOpenLang(!openLang)}>
                        <Lang />
                        <p>{lang}</p>
                        <LangArr />

                        {openLang && <ul className="position-absolute language_menu">
                            {Array.isArray(language) && language?.map((item, index) => (
                                <li key={index} className={`${item === lang && "active"}`} onClick={() => handleLangSwitch(item)}>{item}</li>
                            ))}
                        </ul>}
                    </div>
                </div>

                <div className="d-flex align-items-center h-100 sub_s_main_header">
                    <div className="d-flex align-items-center h-100 social">
                        {Array.isArray(social) && social?.map((item, index) => (
                            <Link to={item?.url} key={index} className="social_icon" onClick={() => setSelectSocial(item)}><item.icon /></Link>
                        ))}
                    </div>

                    <div className="login_title d-flex align-items-center h-100 position-relative " onClick={handleNavigate} ref={ref}>
                        {!!user ?
                            <div className="d-flex align-items-center gap-2">
                                <Profile />
                                <h1>{user?.first_name} {" "} {user?.last_name}</h1>
                            </div>
                            :
                            <div className="d-flex align-items-center gap-2">
                                <Profile />
                                <h1>{t('top-header.login')}</h1>
                            </div>
                        }

                        {profile && <ProfilePopup user={user} setIsLogin={setIsLogin} setUser={setUser} />}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default MainHeader;