import { withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { LocaleContext } from 'App'
import React, { useContext, useState, useRef, useEffect } from 'react'
import {LanguageSvg} from './AllSvg'

const LanguageModal = ({ handleConfirm, handleCancel }) => {
	const { t } = useTranslation('common')
	const lang = useContext(LocaleContext)
	const yesButtonRef = useRef(null)
	
	useEffect(() => {
		// Focus on the "Yes" button when the component mounts or when it becomes visible
		if (yesButtonRef.current) {
			yesButtonRef.current.focus()
		}
	}, []) // Empty dependency array ensures this runs only once after initial render
  
	return (
		<div className="popup">
            <div className="popup-inner">
				<LanguageSvg />
				<p>
					{t('language-change.text')}
				</p>
				<button
					className="yes"
					onClick={handleConfirm}
					ref={yesButtonRef}
				>
					{t('language-change.yes')}
				</button>
				<button
					className="no"
					onClick={handleCancel}
				>
					{t('language-change.no')}
				</button>
            </div>
		</div>
	)
}

export default LanguageModal