import State from 'components/common/State'
import { LocaleContext } from 'App'
import Footer from 'components/corporate/Footer'
import { API_BASE } from 'helpers/pageUtils'
import React, { useContext,Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import MapImg from '../../assets/DMRC-Network-Map-09-Oct-2024-Bilingual.jpg'
import MapImg1 from '../../assets/DMRC-Network-Map-09-Oct-2024-Bilingual.jpg'
import MapPdf from '../../assets/DMRC-Network-Map-09-Oct-2024-Bilingual.pdf'
import MapPdf1 from '../../assets/DMRC-Network-Map-09-Oct-2024-Bilingual.pdf'
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment'
import Magnifier from "react-magnifier";
//import ReactImageMagnify from 'react-image-magnify';
//import '../../styles/mirror.css';


export default function NetworkMap({ data }) {
//   document.getElementById('img-container')?.addEventListener('mouseover', function(){
//     imageZoom('featured')
    
// })
//   function imageZoom(imgID){
//     let img = document.getElementById(imgID)
//     let lens = document.getElementById('lens')
//     let backgroundlens = lens?.style?.backgroundImage
  
//     backgroundlens = `url( ${img?.src} )`
  
//     let ratio = 3
//     let backgroundsize = lens?.style?.backgroundSize
  
//     backgroundsize = (img?.width * ratio) + 'px ' + (img?.height * ratio) + 'px';
  
//     img?.addEventListener("mousemove", moveLens)
//     lens?.addEventListener("mousemove", moveLens)
//     img?.addEventListener("touchmove", moveLens)
  
//     function moveLens(){
//       /*
//           Function sets sets position of lens over image and background image of lens
//           1 - Get cursor position
//           2 - Set top and left position using cursor position - lens width & height / 2
//           3 - Set lens top/left positions based on cursor results
//           4 - Set lens background position & invert
//           5 - Set lens bounds
      
//           */
  
//           //1
//       let pos = getCursor()
//       console.log('**pos:', pos)
  
//       //2
//       let positionLeft = pos.x - (lens?.offsetWidth / 2)
//       let positionTop = pos.y - (lens?.offsetHeight / 2)
  
//       //5
//       if(positionLeft < 0 ){
//         positionLeft = 0
//       }
  
//       if(positionTop < 0 ){
//         positionTop = 0
//       }
  
//       if(positionLeft > img?.width - lens?.offsetWidth /3 ){
//         positionLeft = img?.width - lens?.offsetWidth /3
//       }
  
//       if(positionTop > img?.height - lens?.offsetHeight /3 ){
//         positionTop = img?.height - lens?.offsetHeight /3
//       }
  
  
//       //3
//       lens.style.left = positionLeft + 'px';
//       lens.style.top = positionTop + 'px';
  
//       //4
//       lens.style.backgroundPosition = "-" + (pos.x * ratio) + 'px -' +  (pos.y * ratio) + 'px'
//     }
  
//     function getCursor(){
//       /* Function gets position of mouse in dom and bounds
//            of image to know where mouse is over image when moved
          
//           1 - set "e" to window events
//           2 - Get bounds of image
//           3 - set x to position of mouse on image using pageX/pageY - bounds.left/bounds.top
//           4- Return x and y coordinates for mouse position on image
          
//            */
  
//           let e = window.event
//           let bounds = img?.getBoundingClientRect()
  
//           console.log('**e:', e)
//           console.log('**bounds:', bounds)
//           let X1 = e.pageX
//           let Y1 = e.pageY
//           let x = e?.pageX - bounds?.left
//           let y = e?.pageY - bounds?.top
//           x = x - window?.pageXOffset;
//           y = y - window?.pageYOffset;
      
//       return {'x':x, 'y':y}
//     }
  
//   }
  
//   imageZoom('featured')

    const { t } = useTranslation('passenger')
    const lang = useContext(LocaleContext)
    if (!data) {
      return (
        <>
          <State.Errored message="We didn't find any data" />
          <Footer />
        </>
      )
    }
    return (
      <>
      <div className="container mt-4">
          <h2>{t('plan-journey.network-map')}</h2>
		  <div className="my-4">
          <Magnifier src={lang === 'en' ? MapImg1 : MapImg}  mgShape={'square'}/>
		  </div>
		   <div className="text-center mb-4"><a className="downloadpdf" download="DMRC_MetroMap2021" href={lang === 'en' ? MapPdf1 : MapPdf} target="_blank" title="download map">{t('map.pdf-download')}</a></div>
            {/* <ReactImageMagnify {...{
                      smallImage: {
                          alt: 'network map',
                          isFluidWidth: true,
                          src: MapImg,
                         // srcSet: this.srcSet,
                          //sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                      },
                      largeImage: {
                          src: MapImg,
                          width: 1200,
                          height: 1800
                      },
                      enlargedImageContainerDimensions: {
                        width: '100%',
                        height: '100%'
                    }
                      }} /> */}
          

         
         
              {/* <div id="content-wrapper">

                <div class="column">
                  <div id="img-container">
                    <div id="lens"></div>
                    <img  id="featured" src={MapImg} />
                  </div>
                </div>
                </div> */}
           </div>
        
      <Footer />
      </>
    )
  }
  