import State from 'components/common/State'
import { LocaleContext } from 'App'
import Footer from 'components/corporate/Footer'
import { API_BASE } from 'helpers/pageUtils'
import React, { useContext,Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useHistory } from 'react-router-dom'


export default function news_details({ data }) {
  const { t } = useTranslation('common')
  let history = useHistory();
  const lang = useContext(LocaleContext)
  if (!data) {
    return (
      <>
        <State.Errored message="We didn't find any data" />
        <Footer />
      </>
    )
  }
  return (
    <>
    <div className="container">
      {/* <div className='careerbg'>
        <h1>{t('corporate-header.career')}</h1>
      </div> */}
      {data ? (
        <>
        <h3 className="my-4 pressreleasetitle" dangerouslySetInnerHTML={{
            __html: (data?.news_title ) || '',
            }}/>

      
        <h2  dangerouslySetInnerHTML={{
            __html: (data?.newspaper_name) || '',
            }}/>
        
        <span
            dangerouslySetInnerHTML={{
            __html: (data?.news_date ) || '',
            }}
        />
        <h3
            dangerouslySetInnerHTML={{
            __html: (data?.paper_place) || '',
            }}
        />
        <img
            src={`${API_BASE}${data?.upload_news?.file}`}
            alt={data?.upload_news?.title}
            style={{ maxWidth: '100%' }}
          />

      
          <div className="d-flex justify-content-center">
        <button onClick={() => history.goBack()} className="btn btn-primary"><i className="fa fa-angle-left me-2" />{t('corporate-header.back')}</button>
          </div>
        </>
      ) : (
        <State.Errored message="We didn't find any data" />
      )}
	  </div>
	<Footer />
    </>
  )
}
