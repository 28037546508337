import State from 'components/common/State'
import Footer from 'components/corporate/Footer'
import { API_BASE } from 'helpers/pageUtils'
import React, { useContext } from 'react'
import { LocaleContext } from 'App'

const Academy = ({ data }) => {
	const lang = useContext(LocaleContext)
	return (
		<>
			<div className='container pb-3'>
				{lang && lang == "hi" ?
					<embed src="/academy/index.html" />
				:
					<embed src="/academy/index.html" />
				}
			</div>
			<Footer />
		</>
  )
}

export default Academy
